<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div>
        <div class="row" v-for="(packag,index) in proofReading" :key="index">
            <h5>Content - {{ index + 1 }} <span class="green-text" v-if="packag.proof_reading_trans.length > 0"> Completed</span>
            </h5>
            <div class="light-blue lighten-5 padding-12">
                <p>
                    {{ packag.question }}
                </p>
                <p class="center" v-if="!packag.isShow && packag.proof_reading_trans.length === 0">
                    <a @click="showContent(index)"
                       class="btn waves-effect waves-light green lighten-1" type="submit"
                       name="action">
                        Edit this Content
                    </a>
                </p>
                <p v-if="packag.isShow">
                            <textarea v-validate="'required'" v-model="packag.modifiedContent" name="spellchecker"
                                      autofocus spellcheck="true"
                                      style="padding: 1px;
    border: solid 1px #5c8593;
    font-size: 10pt;
    padding: 5px;
    height: 200px;
    color:#42484d;
    background-color:#ffffff;"></textarea> <br/>
                    <span x-text="serverErrors[index]"></span>

                </p>
                <p class="center" v-if="packag.isShow">
                    <a @click="update(index)"
                       class="btn center waves-effect waves-light green lighten-1" type="submit"
                       name="action">
                        Update this Content
                    </a>
                </p>

            </div>

        </div>
    </div>
</template>
<script>
export default {
    props: ["packages"],
    data() {
        return {
            proofReading: [],
            serverErrors: []
        };
    },

    updated() {
        this.$nextTick();
    },
    mounted() {
        this.proofReading = this.packages.questions
        this.proofReading.forEach(function (data) {
            data.isShow = false;
            data.modifiedContent = data.question;
        })
        console.log(this.proofReading);
    },
    methods: {
        showContent(index) {
            let $data = this.proofReading[index];
            $data.isShow = true;
            Vue.set(this.proofReading, index, $data);
        },
        update(index) {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.isLoading = true;
                    axios.post('/member/proof-reading-jobs', this.proofReading[index])
                        .then(({data}) => {
                            console.log(data);
                            this.proofReading = data.questions;
                            this.proofReading.forEach(function (data) {
                                data.isShow = false;
                                data.modifiedContent = data.question;
                            })
                            return this.$message({
                                type: 'success',
                                message: `Proof Reading Submitted`
                            });
                        }).catch(({response}) => {
                        if (response.status === 422) {
                            this.$message({
                                type: 'error',
                                message: `It seems you missed something to correct.`
                            });
                            Vue.set(this.serverErrors, index, 'Other users have corrected more on this content');
                        }
                    });
                }
            });
        }
    }
}
</script>
