<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="padding-5">
        <vue-element-loading :active="isLoading" color="#FF6700" spinner="bar-fade-scale"/>
        <div class="row">
            <div class="col s12">
                <div class="row">
                    <div class="col s6">
                        <h5>MY PROFILE</h5>
                    </div>
                    <div class="col s6">
                        <a class="btn button right" href="/password-update">Change Password</a>
                    </div>
                </div>
                <div class="row blue lighten-5 row-margin-bottom-0 bordered rounded-sm">
                    <div class="col s12">
                        <div class="row padding-12 row-margin-bottom-0">
                            <div class="input-field col s12">
                                <input id="fullname" v-model="profile.name" v-validate="'required'" name="name"
                                       type="text"/>
                                <label :class="profile.name? 'active':''" for="fullname">Full Name</label>
                                <span class="error-text">{{ errors.first('name') }}</span>
                                <span v-if="serverErrors.name" class="helper-text red-text">{{
                                        serverErrors.name[0]
                                    }}</span>
                            </div>
                            <div class="input-field col s12">
                                <input id="email_id" v-model="profile.email" readonly
                                       type="text"/>
                                <label :class="profile.email? 'active':''" for="email_id">Email</label>
                            </div>
                            <div class="input-field col s12">
                                <select id="gender" v-model="profile.gender" v-validate="'required'" class="select"
                                        name="gender">
                                    <option value="">--Select--</option>
                                    <option v-for="(item, index) in genders" :selected="item.desc == profile.gender"
                                            :value="item.desc">{{ item.desc }}
                                    </option>
                                </select>
                                <label :class="profile.gender? 'active':''" for="gender">Gender</label>
                                <span class="error-text">{{ errors.first('gender') }}</span>
                                <span v-if="serverErrors.gender" class="helper-text red-text">{{
                                        serverErrors.gender[0]
                                    }}</span>
                            </div>
                            <div class="input-field col s12">
                                <input id="flat_no" v-model="profile.address.door_no" v-validate="'required'"
                                       data-vv-as="door number" name="door"
                                       type="text"/>
                                <label :class="profile.address.door_no? 'active':''" for="flat_no">Flat/Door/House
                                    No</label>
                                <span class="error-text">{{ errors.first('door') }}</span>
                                <span v-if="serverErrors.door_no"
                                      class="helper-text red-text">{{ serverErrors.door_no[0] }}</span>

                            </div>
                            <div class="input-field col s12">
                                <input id="address_1" v-model="profile.address.address_line1" v-validate="'required'"
                                       data-vv-as="address line 1" name="address_line1"
                                       type="text"/>
                                <label :class="profile.address.address_line1? 'active':''" for="address_1">Address Line
                                    1</label>
                                <span class="error-text">{{ errors.first('address_line1') }}</span>
                                <span v-if="serverErrors.address_line1"
                                      class="helper-text red-text">{{ serverErrors.address_line1[0] }}</span>
                            </div>
                            <div class="input-field col s12">
                                <input id="address_2" v-model="profile.address.address_line2" v-validate="'required'"
                                       data-vv-as="address line 2" name="address_line2"
                                       type="text"/>
                                <label :class="profile.address.address_line2? 'active':''" for="address_2">Address Line
                                    2</label>
                                <span class="error-text">{{ errors.first('address_line2') }}</span>
                                <span v-if="serverErrors.address_line2"
                                      class="helper-text red-text">{{ serverErrors.address_line2[0] }}</span>
                            </div>
                            <div class="input-field col s12">
                                <select id="ddl_state" v-model="profile.address.state_id" v-validate="'required'"
                                        class="select states" name="state">
                                    <option value="">--Select--</option>
                                    <option v-for="(state, index) in states" :selected="state.id == profile.address.state_id"
                                            :value="state.id">{{ state.name }}
                                    </option>
                                </select>
                                <label :class="profile.address.state_id? 'active':''" for="ddl_state">State</label>
                                <span class="error-text">{{ errors.first('state') }}</span>
                                <span v-if="serverErrors.state_id"
                                      class="helper-text red-text">state field is required</span>
                            </div>
                            <div class="input-field col s12">
                                <input id="city" v-model="profile.address.city" v-validate="'required'"
                                       name="city" type="text"/>
                                <label :class="profile.address.city? 'active':''" for="city">City</label>
                                <span class="error-text">{{ errors.first('city') }}</span>
                                <span v-if="serverErrors.city" class="helper-text red-text">{{
                                        serverErrors.city[0]
                                    }}</span>
                            </div>
                            <div class="input-field col s12">
                                <input id="pincode" v-model="profile.address.pincode" v-validate="'required'"
                                       name="pincode" type="text"/>
                                <label :class="profile.address.pincode? 'active':''" for="pincode">Pincode</label>
                                <span class="error-text">{{ errors.first('pincode') }}</span>
                                <span v-if="serverErrors.pincode"
                                      class="helper-text red-text">{{ serverErrors.pincode[0] }}</span>
                            </div>
                            <div class="input-field col s12">
                                <input id="landmark" v-model="profile.address.landmark" v-validate="'required'"
                                       name="landmark" type="text"/>
                                <label :class="profile.address.landmark? 'active':''" for="landmark">Landmark</label>
                                <span class="error-text">{{ errors.first('landmark') }}</span>
                                <span v-if="serverErrors.landmark"
                                      class="helper-text red-text">{{ serverErrors.landmark[0] }}</span>
                            </div>
                            <div class="input-field col s12">
                                <input id="mobile" v-model="profile.mobile" v-validate="'required'"
                                       :disabled="profile.mobile_verified" name="mobile" type="text"/>
                                <label :class="profile.mobile? 'active':''" for="mobile">Mobile</label>
                                <span class="error-text">{{ errors.first('mobile') }}</span>
                                <span v-if="serverErrors.mobile" class="helper-text red-text">{{
                                        serverErrors.mobile[0]
                                    }}</span>
                            </div>
                            <div class="input-field col s12">
                                <button class="btn button" @click="update">UPDATE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            profile: {
                id: '',
                name: '',
                email: '',
                gender: '',
                mobile: '',
                address: {
                    id: '',
                    door_no: '',
                    address_line1: '',
                    address_line2: '',
                    city: '',
                    pincode: '',
                    landmark: '',
                    state_id: ''
                },
                state_id: '',
                mobile_verified: false,
                mobile_verified_at: null,
            },
            states: [],
            genders: [{id: 1, desc: 'male'}, {id: 2, desc: 'female'}],
            isLoading: false,
            serverErrors: [],
            mobileErrors: [],
            isShowMobileText: true,
            mobile: ''

        };
    },
    updated() {
        const select = document.querySelectorAll('select');
        M.FormSelect.init(select);
    },
    mounted() {
        this.fetch();
        /* this.$echo.private(`App.User.${currentUser.id}`).listen('.mobile.verified', (payload) => {
             this.fetch();
         });*/
    },
    methods: {
        fetch() {
            this.isLoading = true;
            return axios.get('/member-profile/get-details')
                .then(({data}) => {
                    this.mobile = data.profile.mobile
                    this.isLoading = false;
                    this.states = data.states;
                    this.profile = data.profile;
                    this.profile.mobile_verified = (this.profile.mobile_verified_at != null) ? true : false;
                    if (_.isNull(this.profile.address)) {
                        this.profile.address = {
                            id: '',
                            door_no: '',
                            address_line1: '',
                            address_line2: '',
                            city: '',
                            pincode: '',
                            landmark: '',
                            state_id: ''
                        }
                    }
                });
        },
        update() {
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.isLoading = true;
                    axios.post('/member-profile/update-details', {
                        user_id: this.profile.id,
                        name: this.profile.name,
                        gender: this.profile.gender,
                        mobile: this.profile.mobile,
                        address_id: this.profile.address.id,
                        door_no: this.profile.address.door_no,
                        address_line1: this.profile.address.address_line1,
                        address_line2: this.profile.address.address_line2,
                        state_id: this.profile.address.state_id,
                        city: this.profile.address.city,
                        pincode: this.profile.address.pincode,
                        landmark: this.profile.address.landmark
                    })
                        .then(({data}) => {
                            console.log(data);
                            this.profile.address.id = data.id;
                            this.isLoading = false;
                            this.serverErrors = [];
                            return this.$message({
                                type: 'success',
                                message: `Profile Updated!`
                            });
                        }).catch(({response}) => {
                        this.isLoading = false;
                        if (response.status == 422) {
                            this.serverErrors = response.data.errors;
                        }
                    });
                }
            });
        },
        verifyMobile(scope) {
            this.$validator.validateAll(scope).then((result) => {
                if (result) {
                    this.isLoading = true;
                    axios.put('/api/mobile', {mobile: this.mobile})
                        .then(({response}) => {
                            this.isLoading = false;
                            this.serverErrors = [];
                            this.isShowMobileText = false;
                            M.toast({html: "Give a missed Call!", classes: 'teal'});

                        }).catch(({response}) => {
                        this.isLoading = false;
                        if (response.status == 422) {
                            this.mobileErrors = response.data.errors;
                        }
                        M.toast({html: "Please provide a valid Mobile!", classes: 'orange'});
                    });
                }
            });

        }
    }
}
</script>
