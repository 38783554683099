<template>

            <form class="col s12 bordered rounded-sm">
                <vue-element-loading :active="isFormLoading" spinner="bar-fade-scale" color="#5661b3"/>
                <div class="row">
                    <div class="input-field col s12 m11 l11">
                        <input id="title" name="title" type="text" v-validate="'required'" v-model="talent.title"
                               class="validate">
                        <label for="title" :class="talent.title? 'active':''">Title</label>
                        <span v-show="errors.has('title')"
                              class="helper-text red-text">{{ errors.first('title') }}</span>
                        <span v-if="serverErrors.title" class="helper-text red-text">{{ serverErrors.title[0] }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="input-field col s12 m11 l11">
                        <treeselect
                            :options="categories"
                            :default-expand-level="1"
                            :disable-branch-nodes="true"
                            :multiple="true"
                            :clearable="true"
                            :searchable="true"
                            v-model="talent.category_ids"
                            :show-count="true"
                            placeholder="select parent category"
                        />
                    </div>
                </div>
                <div class="row">
                    <span style="padding: 10px">Featured Image</span>
                    <div class="col s12">
                        <div class="upload-btn-wrapper">
                            <a class="btn"><i class="material-icons right">cloud_upload</i>
                                <span v-if="talent.image.length <= 0">Add a Image</span>
                                <span v-else>change a Image</span>
                            </a>
                            <input type="file"
                                   accept="image/*"
                                   name="image" @change="previewImage"/>
                        </div>
                    </div>
                    <div class="col s12">
                        <span v-show="errors.has('image')"
                              class="helper-text red-text">{{ errors.first('image') }}</span>
                        <span v-if="serverErrors.image" class="helper-text red-text">{{ serverErrors.image[0] }}</span>
                    </div>
                </div>
                <div class="row" v-show="talent.image.length > 0">
                    <div class="col s12">
                        <div class="image-preview">
                            <img class="materialboxed preview" width="150" :src="talent.image">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m11 l11">
                    <editor api-key="ni2jdsrs5j8s323wnta6pj2culmt7ak0oakui8sdkqaq5joo"
                            v-model="talent.description" :toolbar="toolbar" :plugins="plugins" :init="init"></editor>
                    <span v-show="errors.has('description')"
                          class="helper-text red-text">{{ errors.first('description') }}</span>
                    <span v-if="serverErrors.description" class="helper-text red-text">{{
                            serverErrors.description[0]
                        }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s6">
                        <input id="price" name="price" type="text" v-validate="'required'" v-model="talent.price"
                               class="validate">
                        <label for="price" :class="talent.price? 'active':''">Price</label>
                        <span v-show="errors.has('price')"
                              class="helper-text red-text">{{ errors.first('price') }}</span>
                        <span v-if="serverErrors.price" class="helper-text red-text">{{ serverErrors.price[0] }}</span>
                    </div>
                    <div class="col s6">
                        <div class="row">
                            <div class="col s6">
                                <p>
                                    <label>
                                        <input name="price_type" @input="priceType" value="hours" type="radio"
                                               :checked="talent.price_type=='hours'"/>
                                        <span>Hourly</span>
                                    </label>
                                </p>
                            </div>
                            <div class="col s6">
                                <p>
                                    <label>
                                        <input name="price_type" @input="priceType" value="fixed" type="radio"
                                               :checked="talent.price_type=='fixed'"/>
                                        <span>Fixed</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" input-field col s6">
                        <input id="duration" name="duration" type="number" v-validate="'required'"
                               v-model="talent.duration"
                               class="validate">
                        <label for="price" :class="talent.duration? 'active':''">Duration</label>
                        <span v-show="errors.has('duration')"
                              class="helper-text red-text">{{ errors.first('duration') }}</span>
                        <span v-if="serverErrors.title" class="helper-text red-text">{{
                                serverErrors.duration[0]
                            }}</span>
                    </div>
                    <div class="col s6">
                        <div class="row">
                            <div class="col s6">
                                <p>
                                    <label>
                                        <input name="duration_type" @input="durationType" value="hours"
                                               :checked="talent.duration_type=='hours'" type="radio"
                                        />
                                        <span>Hourly</span>
                                    </label>
                                </p>
                            </div>
                            <div class="col s6">
                                <p>
                                    <label>
                                        <input name="duration_type" @input="durationType" value="days" type="radio"
                                               :checked="talent.duration_type=='days'"/>
                                        <span>Days</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s4">
                        <a class="waves-effect waves-light btn teal" @click.prevent="submittalent"
                           :disabled="isLoading">
                            <div class="progress progress-btn" v-if="isLoading">
                                <div class="indeterminate"></div>
                            </div>
                            <i
                                class="material-icons left">send</i>Submit
                            talent</a>
                    </div>
                </div>
            </form>

</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    name: "EditTalent",
    props: ["talentData"],
    components: {
        Treeselect
    },
    data() {
        return {
            init: {
                height: '400px',
                setup: function (editor) {
                    editor.on('NodeChange', function (e) {
                        if (e.element.tagName === "IMG") {
                            e.element.setAttribute("loading", "lazy");
                        }
                    });
                },
                image_class_list: [
                    {title: 'Image Response', value: 'responsive-img'},
                ],
                images_upload_handler: function (blobInfo, success, failure) {
                    let formData = new FormData();
                    formData.append('file', blobInfo.blob(), blobInfo.filename());
                    axios.talent('/api/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(({data}) => {
                        success(data.url);
                    }).catch(({response}) => {
                        failure('Invalid JSON: ' + response.data.errors);
                        return;
                    });
                }
            },
            toolbar: [
                'newdocument fullpage | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | styleselect formatselect fontselect fontsizeselect',
                'cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink anchor code | insertdatetime preview | forecolor backcolor',
                'table | hr removeformat | subscript superscript | charmap emoticons | print fullscreen | ltr rtl | spellchecker | visualchars visualblocks nonbreaking template pagebreak restoredraft'
            ],
            plugins: ["advlist autolink autosave link  lists charmap print preview hr anchor pagebreak spellchecker", "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime  nonbreaking", "table contextmenu directionality emoticons  textcolor paste  textcolor colorpicker textpattern"],
            isFormLoading: true,
            isLoading: false,
            categories: [],
            serverErrors: [],
            imgSrc: '',
            talent: {
                id: '',
                category_ids: [],
                title: '',
                description: '',
                duration: '',
                duration_type: 'days',
                price: '',
                price_type: 'fixed',
                image: '',
                status:2
            },
        }
    },
    methods:
        {
            toDataURL(src, callback, outputFormat) {
                var img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function () {
                    var canvas = document.createElement('CANVAS');
                    var ctx = canvas.getContext('2d');
                    var dataURL;
                    canvas.height = this.naturalHeight;
                    canvas.width = this.naturalWidth;
                    ctx.drawImage(this, 0, 0);
                    dataURL = canvas.toDataURL(outputFormat);
                    callback(dataURL);
                };
                img.src = src;
                if (img.complete || img.complete === undefined) {
                    img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                    img.src = src;
                }
            },
            priceType(event) {
                this.talent.price_type = event.target.value;
            },
            durationType(event) {
                this.talent.duration_type = event.target.value;
            },
            previewImage(event) {
                const input = event.target;
                if (input.files && input.files[0]) {
                    const reader = new FileReader();
                    reader.onload = e => {
                        this.talent.image = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            },
            submittalent() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                            return axios.post('/api/talent', this.talent)
                                .then(({data}) => {
                                    this.isLoading = false;
                                    this.$message({
                                        type: 'success',
                                        message: `talent ${data.title} Updated!`
                                    });
                                    this.$validator.errors.clear();
                                }).catch(({response}) => {
                                    this.isLoading = false;
                                    if (response.status == 422) {
                                        this.serverErrors = response.data.errors;
                                    }
                                    this.$message({
                                        type: 'warning',
                                        message: 'Validation failed!'
                                    });

                                });
                    }
                    return this.$message({
                        type: 'warning',
                        message: 'Validation failed!'
                    });
                });
            }
            ,
        }
    ,
    updated() {
        const select = document.getElementById('select');
        M.FormSelect.init(select);
    }
    ,
    mounted() {
        axios.get('/api/talent/categories')
            .then(({data}) => {
                this.categories = data;
                this.isFormLoading = false;
            });
    }
}
</script>
<style scoped>
.upload-btn-wrapper {
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

img.preview {
    background-color: white;
    border: 1px solid #ddd;
    padding: 3px;
}
</style>
