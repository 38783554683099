/*
 * *
 *  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
 *  *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  *  * Proprietary and confidential
 *  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
 *  *
 *
 */
//import VueEcho from "vue-echo";

require('./member');
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
window.Vue = require('vue');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import {DatePicker, Message, MessageBox, Step, Steps} from 'element-ui';
import 'element-ui/lib/theme-chalk/date-picker.css';
import 'element-ui/lib/theme-chalk/message-box.css';
import 'element-ui/lib/theme-chalk/message.css';
import 'element-ui/lib/theme-chalk/step.css';
import 'element-ui/lib/theme-chalk/steps.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import VeeValidate, {Validator} from 'vee-validate';
import VueElementLoading from 'vue-element-loading';
import VueLazyload from 'vue-lazyload';
import VueRouter from 'vue-router';

import VueCountable from 'vue-countable'
Vue.component('vue-countable', VueCountable)
const dictionary = {
    en: {
        attributes: {
            param_1: 'name',
            param_2: 'email'
        },
    }
};
Vue.use(VueLazyload);

Validator.localize(dictionary);
locale.use(lang);
Vue.use(VeeValidate, {
    events: ''
});
Vue.use(DatePicker);
Vue.use(VueRouter);
Vue.use(Steps);
Vue.use(Step);
Vue.use(require('vue-script2'));
/*Vue.use(VueEcho, {
    broadcaster: 'socket.io',
    host: window.location.hostname
});*/

Vue.component('VueElementLoading', VueElementLoading);
Vue.component('comment-form', require('./components/post/CommentForm').default);
Vue.component('comment-reply-form', require('./components/post/CommentReplyForm').default);
Vue.component('profile', require('./components/member/Profile').default);
Vue.component('payment-request', require('./components/member/PaymentRequestManual').default);
Vue.component('mobile-banner', require('./components/member/MobileBanner').default);
Vue.component('post-talent', require('./components/member/Talent').default);
Vue.component('proof-reading',require('./components/member/ProofReading').default);

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
const routes = [
    {
        path: '/member-earnings/payment',
        component: () => import('./components/member/Payment'),
        name: 'payment',
        props: true
    },
    {
        path: '/member-earnings/request',
        component: () => import('./components/member/PaymentRequest'),
        name: 'paymentRequest',
        props: true
    },
    {
        path: '/member-earnings/confirm',
        component: () => import('./components/member/ConfirmPayment'),
        name: 'confirmPayment',
        props: true
    },
];
const router = new VueRouter({
    mode: 'history',
    routes,
    linkActiveClass: "link-active", // active class for non-exact links.
    linkExactActiveClass: "link-active" // active class for *exact* links.
});
const app = new Vue({
        el: '#app',
        router,
        data() {
            return {
                isError: true,
                isLoading: false,
                title_count:window.talent.subject_limit,
                description_count:window.talent.content_limit,
                title:'',
                description:'',
                bonusEmailLoading: [],
                proofReading:[],
                webcrawler: {
                    adId: '',
                    webaddress1: '',
                    webaddress2: '',
                    webaddress3: '',
                    webaddress4: '',
                    webaddress5: ''
                },
                email: '',
                password: '',
                password_confirmation: '',
                token: '',
                subCategories: [],
                serverErrors: []
            }
        },
        updated() {
            const select = document.getElementById('subCategory');
            M.FormSelect.init(select);
        },
        computed: {
            rules() {
                if (this.webcrawler.webaddress1.length === 0 &&
                    this.webcrawler.webaddress2.length === 0 &&
                    this.webcrawler.webaddress3.length === 0 &&
                    this.webcrawler.webaddress4.length === 0 &&
                    this.webcrawler.webaddress5.length === 0) {
                    return 'required';
                }
                return '';
            }
        },

        created() {
            const email = document.getElementById('email');
            if (email) {
                this.email = email.value;
            }
            const token = document.getElementById('pass-token');
            if (token) {
                this.token = token.value;
            }
        },
        methods: {
            titleCount(event){
                if(this.title_count > 0) {
                  return  this.title_count = (window.talent.subject_limit) - event.words;
                }
                return this.title_count=0;
            },
            editContent(id,question){
               this.proofReading.push({
                   id: id,
                   question:question
               });
               console.log(this.proofReading);
            },
            wordCount(e){
                console.log(e);
            },
            descriptionCount(event){
                if(this.description_count > 0) {
                    return  this.description_count = (window.talent.content_limit) - event.words;
                }
                return this.description_count=0;
            },
            selectSubCategory(event) {
                axios.get('/api/talent/categories/' + event.target.value)
                    .then(({data}) => {
                        this.subCategories = data;
                    })
                    .catch(({response}) => {

                    });
            },
            registerForm(event, scope) {
                this.isError = false;
                this.isLoading = false;
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        this.isLoading = true;
                        (event.target).submit();
                    }
                });
            },
            loginForm(event) {
                this.isError = false;
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        (event.target).submit();
                    }
                });
            },
            articleForm(event) {
                this.isError = false;
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        (event.target).submit();
                    }
                });
            },
            forgotPasswordForm(event) {
                this.serverErrors = [];
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.post('/api/forgot-password', {email: this.email})
                            .then(({data}) => {
                                return this.$message({
                                    type: 'success',
                                    message: `Reset Link sent!`
                                });
                            })
                            .catch(({response}) => {
                                this.isLoading = false;
                                if (response.status == 422) {
                                    this.serverErrors = response.data.errors;
                                    return this.$message({
                                        type: 'warning',
                                        message: `Please validate email!`
                                    });
                                }
                            });
                    }
                });
            },
            passwordCreate(event) {
                this.isError = false;
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        (event.target).submit();
                    }
                });
            },
            links(event, scope) {
                this.isError = false;
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        (event.target).submit();
                    }
                });
            },
            referrals(event, scope) {
                this.$validator.validateAll(scope).then((result) => {
                    console.log(result);
                    if (result) {
                        (event.target).submit();
                    }
                });
            },
            requestBonusEmail(bonusEmailId) {
                this.$set(this.bonusEmailLoading, bonusEmailId, true);
                axios.post('/bonus-email', {id: bonusEmailId})
                    .then(({data}) => {
                        this.$set(this.bonusEmailLoading, bonusEmailId, false);
                        document.getElementById("bonus-" + bonusEmailId).outerHTML = '<i class="material-icons small teal-text">check</i>';
                        this.$alert('Bonus Email Sent', 'Bonus Email', {
                            confirmButtonText: 'OK',
                            callback: action => {
                                location.reload();
                            }
                        });

                    })
                    .catch(({response}) => {
                        this.$set(this.bonusEmailLoading, bonusEmailId, false);
                        this.$alert(response.data.errors.id[0], 'Request Mail', {
                            confirmButtonText: 'OK',
                        });

                    });
            },
            requestPayment(minPayout, unPaidAmount, isAddressMobile) {
                if (isAddressMobile == 0) {
                    return window.location.href = "/member-profile";
                }
                this.isLoading = true;
                this.sendOTP();
                this.$router.push({name: 'paymentRequest', params: {minPayout: minPayout, unPaidAmount: unPaidAmount}});
            },
            withdrawAmount(paymentId, isBankAdded, account) {
                let agent = document.getElementById('payment-approved');
                agent.disabled = true;
                if (_.isEmpty(isBankAdded)) {
                    //this.agentSingup(paymentId);
                    this.$router.push({name: 'payment', params: {paymentId: paymentId}});
                } else {
                    this.sendOTP();
                    this.$router.push({
                        name: 'confirmPayment',
                        params: {paymentId: paymentId, account: account}
                    });
                }
            },
            agentSingup(paymentId) {
                let agent = document.getElementById('payment-approved');
                agent.innerHTML = 'Requesting.....';
                agent.disabled = true;
                axios.post('/api/payment/agent')
                    .then(({data}) => {
                        this.isLoading = true;
                        this.$router.push({name: 'payment', params: {paymentId: paymentId, joloOTP: data.otp}});
                        if (parseInt(data.otp)) {
                            return this.$message({
                                type: 'success',
                                message: `Otp sent!`
                            });
                            return this.$message({
                                type: 'success',
                                message: `Please add beneficiary details!`
                            });
                        }
                    })
                    .catch(({response}) => {
                        agent.innerHTML = '<i class="fas fa-rupee-sign"></i>&nbsp; Withdraw Amount';
                        agent.disabled = false;
                        this.isLoading = false;
                        return this.$message({
                            type: 'error',
                            message: `Something Wrong.Please try again later!`
                        });
                    });
            },
            sendOTP() {
                axios.post('/api/payment/otp')
                    .then(({data}) => {
                        return this.$message({
                            type: 'success',
                            message: `Otp sent!`
                        });
                    })
                    .catch(({response}) => {

                        this.isLoading = false;
                        if (response.status == 429) {
                            window.location.href = '/logout';
                            return this.$message({
                                type: 'error',
                                message: `Too many attempts!`
                            });
                        }
                        if (response.status == 422) {
                            window.location.href = '/member-profile';
                            return this.$message({
                                type: 'error',
                                message: `Please verify your mobile!`
                            });
                        }
                        return this.$message({
                            type: 'error',
                            message: `Something Wrong.Please try again later!`
                        });
                    });
            },
            submitWebCrawler() {
                this.serverErrors = [];
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.post('/webcrawler', this.webcrawler)
                            .then(({data}) => {
                                this.isLoading = false;
                                return this.$message({
                                    type: 'success',
                                    message: 'Successfully Submitted!'
                                });
                            })
                            .catch(({response}) => {
                                this.$message({
                                    type: 'error',
                                    message: 'Error in submission'
                                });
                                if (response.data.errors) {
                                    this.serverErrors = response.data.errors;
                                } else {
                                    this.serverErrors = response.data;
                                }
                                this.isLoading = false;
                            });
                    }
                });
            },
            onCopy(id) {
                const copyText = document.getElementById(id);
                copyText.select();
                document.execCommand("copy");
                M.toast({html: "Text Copied", classes: 'rounded cyan'});
            },
            popup(event) {
                const popupSize = {
                    width: 780,
                    height: 550
                };
                let verticalPos = Math.floor((document.documentElement.clientWidth - popupSize.width) / 2),
                    horisontalPos = Math.floor((document.documentElement.clientHeight - popupSize.height) / 2);

                let popup = window.open((event.target).href, 'social',
                    'width=' + popupSize.width + ',height=' + popupSize.height +
                    ',left=' + verticalPos + ',top=' + horisontalPos +
                    ',location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1');

                if (popup) {
                    popup.focus();
                    e.preventDefault();
                }
            },
            onResetPassword(event) {
                this.isLoading = true;
                this.serverErrors = [];
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        axios.post('/reset-password', {
                            email: this.email,
                            password: this.password,
                            password_confirmation: this.password_confirmation,
                            token: this.token
                        })
                            .then(({data}) => {
                                this.isLoading = false;
                                this.$validator.reset();
                                return this.$message({
                                    type: 'success',
                                    message: `Password Updated!`
                                });
                            }).catch(({response}) => {
                            this.isLoading = false;
                            if (response.status == 422) {
                                this.serverErrors = response.data.errors;
                            }
                            return this.$message({
                                type: 'warning',
                                message: 'Update failed!'
                            });
                        });
                    } else {
                        this.isLoading = false;
                    }
                });
            }
        }
    })
;
