<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <form class="col s12" @submit.prevent="submitComment()">
        <div class="row">
            <div class="col s12 blue lighten-5">
                <h5>Leave a Reply</h5>
            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <textarea class="comment-textarea" v-validate="'required'" name="comment"
                          v-model="comment.text"></textarea>
                <div v-show="errors.has('comment')" class="helper-text red-text">{{ errors.first('comment') }}</div>
                <div v-if="serverErrors.comment"
                     class="helper-text red-text">{{ serverErrors.comment[0] }}
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col s12">
                <button class="btn btn-small red" type="submit" :disabled="isLoading">Post Comment</button>
            </div>
            <div class="col s12" style="margin-top: 5px;">
                          <span v-if="isPosted"
                                class="helper-text green-text">Your Comment has been posted for Moderate!</span>
            </div>
        </div>
    </form>
</template>

<script>
    export default {
        name: "CommentForm",
        props: ["postId"],
        data() {
            return {
                isLoading: false,
                serverErrors: [],
                isPosted: false,
                comment: {
                    post_id: '',
                    text: '',
                }
            }
        },
        mounted() {
            this.comment.post_id = this.postId._id;
        },
        methods: {
            submitComment() {
                if (!window.currentUser) {
                    return M.toast({html: 'Please Login to Post comment!', classes: 'red'});
                }
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.post('/api/comment', this.comment)
                            .then(({data}) => {
                                this.text = '';
                                this.$validator.reset();
                                this.isPosted = true;
                                M.toast({html: 'Comment Saved!', classes: 'teal'});
                            })
                            .catch(({response}) => {
                                this.isLoading = false;
                                if (response.status == 421) {
                                    this.serverErrors = response.data.errors;
                                }
                            });
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .comment-textarea {
        border: 1px solid #9e9e9e !important;
        outline: none;
        height: 30%;
        font-size: 16px;
        padding: 10px;
        font-family: "Droid Sans", sans-serif;
        font-size: 16px;
        font-weight: normal;
        background-color: #fff;
        color: #333;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
        transition: all 200ms ease-in-out 0s;
    }

    .comment-textarea:focus {
        background-color: #e9e9e9;
        outline: none;
    }

</style>
