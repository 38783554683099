<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->
<template>
<div class="row" v-if="isVerified===2"> <!-- Email Verified  -->
    <vue-element-loading :active="isLoading" spinner="bar-fade-scale" color="#FF6700"/>
    <div class="col s12">
        <div class="row yellow darken-2 row-margin-bottom-0">
            <div class="col s12">
                <h5 class="section-title white-text">* WARNING - VERIFY YOUR MOBILE NUMBER</h5>
            </div>
        </div>
        <div class="row padding-12 row-margin-bottom-0 blue lighten-5">
            <div class="col s12 center">
                <div class="row" v-if="isShowMobileText">
                    <form @submit.prevent="verifyMobile('mobile')" data-vv-scope="mobile">
                        <div class="input-field col s8 m6 l4">
                            <input id="verify_mobile" name="mobile" v-model="mobile"
                                   v-validate="'required'" type="text" class="validate">
                            <label for="verify_mobile" :class="mobile? 'active':''">Mobile</label>
                            <span class="error-text">{{ errors.first('mobile.mobile') }}</span>
                            <span v-if="mobileErrors.mobile"
                                  class="helper-text red-text">{{ mobileErrors.mobile[0] }}</span>
                        </div>
                        <div class="input-field col s4 center">
                            <button class="waves-effect waves-light btn" type="submit">Verify
                                Mobile
                            </button>
                        </div>
                    </form>
                </div>
                <div class="row" v-if="!isShowMobileText">
                    <div class="col s12 center">
                        <p>
                            From Your Mobile : <span class="red-text">{{mobile}}</span>
                            <button class="btn-flat green-text" @click="isShowMobileText=true">Change
                                Mobile
                            </button>
                        </p>
                    </div>
                    <div class="col s12 center">
                        <img class="responsive-img" width="650" src="images/missed_call.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        props:{
          isVerified:{
              type:Number,
              required:true
          }
        },
        data(){
            return {
                isLoading: false,
                serverErrors: [],
                mobileErrors:[],
                isShowMobileText: true,
                mobile: ''
            }
        },
        methods:{
            verifyMobile(scope) {
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        this.isLoading = true;
                        axios.put('/api/mobile', {mobile: this.mobile})
                            .then(({response}) => {
                                this.isLoading = false;
                                this.serverErrors = [];
                                this.isShowMobileText = false;
                                M.toast({html: "Give a missed Call!", classes: 'teal'});

                            }).catch(({response}) => {
                            this.isLoading = false;
                            if (response.status == 422) {
                                this.mobileErrors = response.data.errors;
                            }
                            M.toast({html: "Please provide a valid Mobile!", classes: 'orange'});
                        });
                    }
                });

            }
        }
    }
</script>
