<!--
  - /**
  -  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
  -  *  * Unauthorized copying of this file, via any medium is strictly prohibited
  -  *  * Proprietary and confidential
  -  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
  -  *
  -  */
  -->

<template>
    <div class="row">
        <div class="col s12 center-align">
            <div class="row">
                <div class="input-field col s12">
                    <button class="btn button" @click="sendRequest">Withdraw Your Earnings</button>
                </div>
            </div>
            <div class="col s12">
                <span class="font-weight-bold">Note:</span> You can withdraw your Payment when you reach the threshold
                limit of Rs.<span>{{ minPayout }}</span> -/ <br/>
                *Each Payment request will be validated and approved by the payments team within 5-7 days.
            </div>
        </div>
    </div>
</template>

<script>
const toRegexRange = require('to-regex-range');
export default {
    name: "PaymentRequest",
    props: ["minPayout", "unPaidAmount"],
    data() {
        return {
            isLoading: false,
            active: 1,
            amount: 3000,
            serverErrors: [],
            payment_id: ''

        }
    },
    methods: {
        sendRequest() {

            axios.post('/api/payment', {amount: this.unPaidAmount})
                .then(({data}) => {
                    this.$message({
                        type: 'success',
                        message: 'Your Requested Amount is:' + this.unPaidAmount
                    });
                })
                .catch(({response}) => {
                    if (response.status === 422) {
                        this.$message({
                            type: 'error',
                            message: response.data.errors.amount[0]
                        });
                    }
                });
        }
    },
}
</script>

