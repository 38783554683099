/*
 * *
 *  *  * Copyright (C) Signical Webservices Private Limited - All Rights Reserved
 *  *  * Unauthorized copying of this file, via any medium is strictly prohibited
 *  *  * Proprietary and confidential
 *  *  * Written by Sathish Kumar(satz) <sathish.thi@gmail.com>  ManiKandan<smanikandanit@gmail.com >
 *  *
 *
 */

require('./bootstrap');
let carousel_instances = null;
let sidenav_instance = null;

window.addEventListener('load', () => {
    let matches = document.querySelectorAll("ins.ADSENSE");
    Array.from(matches).forEach((element) => {
        console.log(matches);
        let parentElement = element.parentElement;
        if (window.getComputedStyle(parentElement).getPropertyValue("display") === "none")  {
            element.remove();
        } else {
            element.classList.remove("ADSENSE");
            element.classList.add("adsbygoogle");
            (adsbygoogle = window.adsbygoogle || []).push({});
        }
    });

});


document.addEventListener('DOMContentLoaded', function () {
    const carousel_elems = document.querySelectorAll('.carousel');
    M.Carousel.init(carousel_elems, {
        fullWidth: true,
        indicators: true
    });

    const dropdown_elems = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(dropdown_elems, {
        alignment: 'right',
        hover: false,
        coverTrigger: false,
        constrainWidth: true,
        container: "nav-content"
    });

    const sidenav_main = document.querySelectorAll('.sidenav-main');
    M.Sidenav.init(sidenav_main, {});

    const sidenav_right = document.querySelectorAll('#slide-out-right');
    M.Sidenav.init(sidenav_right, {
        edge: 'right'
    });

    const scrollspy_elems = document.querySelectorAll('.scrollspy');
    M.ScrollSpy.init(scrollspy_elems, {
        scrollOffset: 50
    });

    const select = document.querySelectorAll('select');
    M.FormSelect.init(select);

    const tab_elems = document.querySelectorAll('.content-tabs');
    M.Tabs.init(tab_elems, {});

    const collapsible_elems = document.querySelectorAll('.collapsible-menu');
    const collapsible_instances = M.Collapsible.init(collapsible_elems, {accordian: true});

    const datepicker_elems = document.querySelectorAll('.datepicker');
    M.Datepicker.init(datepicker_elems, {format: 'dd-mm-yyyy', autoClose: true});

    const collapse = document.querySelectorAll('.collapsible');
    M.Collapsible.init(collapse);

        const elems = document.querySelectorAll('.modal');
         M.Modal.init(elems);


    autoplay();
     const cloak =  document.getElementById("cloak")
     if(cloak){
         cloak.removeAttribute("v-cloak");
     }
});
function autoplay() {
    if (!_.isNil(carousel_instances)) {
        carousel_instances.forEach(function (element) {
            element.next();
        }, this);
        setTimeout(autoplay, 4000);
    }
}

function fnCloseSideNav() {
    sidenav_instance[0].close();
}
